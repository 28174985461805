function HomePage() {
  return (
    <div className="HomePage">
      <header className="App-header">
        HOME 🏠
      </header>
    </div >
  );
}

export default HomePage;