
function ProfilePage() {
  return (
    <div className="ProfilePage">
      <header className="App-header">
        Profile Page
      </header>
    </div >
  );
}

export default ProfilePage;